define("sccog/initializers/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'analytics',
    initialize: function () {
      Ember.Router.reopen({
        // notify piwik
        didTransition() {
          this._super(...arguments);

          var piwik = window.Piwik && window.Piwik.getAsyncTracker(); // Piwik isn't avaliable on page load, but will likely be when this is called next.

          if (!piwik) {
            return null;
          }

          piwik.setCustomUrl(this.url);
          return piwik.trackPageView(this.url);
        }

      });
    }
  };
  _exports.default = _default;
});