define("sccog/utils/find", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * Similar to _.find, where predicate is a partial object, matched against
   * an array of objects.
   *
   * E.g. find(users, { age: 20 }) => { name: 'Joe', age: 20 }
   */
  function _default(collection, predicate) {
    var result = null;

    for (var i = 0; i < collection.length; i++) {
      var item = collection[i];
      var matches = true;

      for (var key in predicate) {
        // Skip prototype properties.
        // eslint-disable-next-line no-prototype-builtins
        if (!predicate.hasOwnProperty(key)) {
          continue;
        } // Check if key/value match between predicate and item.


        if (item[key] !== predicate[key]) {
          matches = false;
          break;
        }
      }

      if (matches) {
        result = item;
        break;
      }
    }

    return result;
  }
});