define("sccog/pods/documents/new/controller", ["exports", "@ember-decorators/object", "jquery", "sccog/mixins/save-controller", "sccog/utils/pace-monitor"], function (_exports, _object, _jquery, _saveController, _paceMonitor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let NewController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.computed.filterBy('model.properties', 'isDeleted', false), _dec6 = Ember.computed('model.id'), _dec7 = (0, _object.observes)('session.town'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, classic(_class = (_class2 = (_temp = class NewController extends Ember.Controller.extend(_saveController.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appConfig", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      _defineProperty(this, "queryParams", ['lat', 'lng', 'source', 'properties']);

      _initializerDefineProperty(this, "lat", _descriptor3, this);

      _initializerDefineProperty(this, "lng", _descriptor4, this);

      _defineProperty(this, "source", null);

      _defineProperty(this, "properties", null);

      _initializerDefineProperty(this, "validProperties", _descriptor5, this);
    }

    get mapCenter() {
      var lat = this.lat;
      var lng = this.lng;

      if (!lat || !lng) {
        return null;
      }

      return [lat, lng];
    }

    get uploadUrl() {
      let id = this.get('model.id');
      let url = `/api/documents/${id}`;
      return url;
    }

    onModel() {
      var userMuni = this.get('session.town');

      if (userMuni) {
        this.set('municipality', userMuni);
      }
    }

    onCreateSuccess() {
      _paceMonitor.default.done(); // Clear file upload warning if set.


      this.set('thumbnailLimitHit', false);
      this.set('upload', undefined);
      this.toast.success('The document has been created');
      (0, _jquery.default)('body, .main').animate({
        scrollTop: 0
      }, 200);
    }

    addProperty(property) {
      var propertyRecord = this.store.createRecord('property', property || {});
      this.get('model.properties').addObject(propertyRecord);
    }

    deleteProperty(property) {
      this.store.deleteRecord(property);
    }

    save(model) {
      // Show loading indicator.
      _paceMonitor.default.start(); // Save the document model, then upload any files.


      return model.save().then(() => {
        // Save all property changes.
        return model.get('properties').save();
      }).then(() => {
        let upload = this.upload;

        if (!upload) {
          // There are no files to upload, and we are all done.
          return;
        }

        let uploadUrl = this.uploadUrl;
        return upload(uploadUrl);
      }).then(() => {
        this.onCreateSuccess();
        this.send('refreshRoute');
      }).catch(this.handleSaveError.bind(this));
    }

    uploadReady(fn) {
      this.set('upload', fn);
    } // Handle a new type in our dropdown.


    createType(type) {
      this.documentTypes.pushObject(type);
      this.model.set('type', type);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "appConfig", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "lat", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "lng", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "validProperties", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "uploadUrl", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "uploadUrl"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onModel", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "onModel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addProperty", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "addProperty"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteProperty", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteProperty"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "uploadReady", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "uploadReady"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createType", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "createType"), _class2.prototype)), _class2)) || _class);
  _exports.default = NewController;
});