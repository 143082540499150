define("sccog/components/help-tooltip", ["exports", "@ember-decorators/component", "jquery"], function (_exports, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a
    class='fa fa-question-circle help-tooltip-icon'
    tabindex='0'
    ...attributes
    {{did-insert this.setupTooltip}}
  >
  </a>
  */
  {"id":"/yHEbKuH","block":"{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"a\"],[24,0,\"fa fa-question-circle help-tooltip-icon\"],[24,\"tabindex\",\"0\"],[17,1],[4,[38,0],[[32,0,[\"setupTooltip\"]]],null],[12],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}","meta":{"moduleName":"sccog/components/help-tooltip.hbs"}});

  const classic = __EMBER_CLASSIC_DECORATOR;
  let HelpTooltip = (_dec = (0, _component.tagName)(''), _dec2 = Ember._action, classic(_class = _dec(_class = (_class2 = class HelpTooltip extends Ember.Component {
    setupTooltip(el) {
      (0, _jquery.default)(el).tooltip({
        title: this.content,
        container: '.main',
        placement: 'auto top',
        viewport: '.main'
      });
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "setupTooltip", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "setupTooltip"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = HelpTooltip;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, HelpTooltip);
});