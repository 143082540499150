define("sccog/pods/myaccount/route", ["exports", "sccog/utils/dropdown-data", "sccog/mixins/authenticated-route", "sccog/mixins/reset-scroll"], function (_exports, _dropdownData, _authenticatedRoute, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let MyaccountRoute = classic(_class = class MyaccountRoute extends Ember.Route.extend(_authenticatedRoute.default, _resetScroll.default) {
    model() {
      var id = this.get('session.id');
      var user = this.store.findRecord('user', id);
      var roles = (0, _dropdownData.getRoles)();
      return Ember.RSVP.hash({
        user,
        roles
      });
    }

    setupController(controller, model) {
      controller.setProperties({
        model: model.user,
        roles: model.roles
      });
    }

  }) || _class;

  _exports.default = MyaccountRoute;
});