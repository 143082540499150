define("sccog/pods/user/edit/controller", ["exports", "jquery", "sccog/mixins/save-controller", "sccog/utils/pace-monitor"], function (_exports, _jquery, _saveController, _paceMonitor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let EditController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('role', 'appConfig.config.multipleMunicipality'), _dec4 = Ember._action, classic(_class = (_class2 = (_temp = class EditController extends Ember.Controller.extend(_saveController.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appConfig", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      _defineProperty(this, "repeatPassword", null);
    }

    get shouldShowMunicipality() {
      return this.get('appConfig.config.multipleMunicipality') && this.role !== 'admin';
    }

    passwordsMatch() {
      let password = this.password;
      let repeatPassword = this.repeatPassword;

      if (password && repeatPassword !== password) {
        return false;
      } else {
        return true;
      }
    }

    save(model) {
      if (!this.passwordsMatch()) {
        this.toast.error('The passwords do not match');
        return;
      } // Admins should not have a town associated with them


      if (this.role === 'admin') {
        this.set('town', null);
      } // Show loading indicator.


      _paceMonitor.default.start(); // Save the user model.


      model.save().then(() => {
        this.toast.success('The user has been saved');
        this.set('password', null);
        this.set('repeatPassword', null);
        (0, _jquery.default)('body, .main').animate({
          scrollTop: 0
        }, 200);
      }).catch(this.handleSaveError.bind(this)).finally(_paceMonitor.default.done);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "appConfig", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "shouldShowMunicipality", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldShowMunicipality"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2)) || _class);
  _exports.default = EditController;
});