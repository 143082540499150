define("sccog/models/document", ["exports", "@ember-decorators/object", "@ember-data/model", "sccog/config/environment"], function (_exports, _object, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let MyDocument = (_dec = Ember.inject.service, _dec2 = (0, _model.attr)(), _dec3 = (0, _model.attr)(), _dec4 = (0, _model.attr)(), _dec5 = (0, _model.hasMany)('property', {
    async: false
  }), _dec6 = (0, _model.attr)(), _dec7 = (0, _model.attr)(), _dec8 = (0, _model.attr)(), _dec9 = (0, _model.attr)(), _dec10 = (0, _model.attr)(), _dec11 = (0, _model.attr)(), _dec12 = (0, _model.attr)(), _dec13 = (0, _model.attr)(), _dec14 = (0, _model.attr)(), _dec15 = (0, _model.attr)(), _dec16 = (0, _model.attr)(), _dec17 = (0, _model.attr)(), _dec18 = (0, _model.attr)(), _dec19 = (0, _model.attr)(), _dec20 = Ember.computed('properties', 'municipality'), _dec21 = Ember.computed('thumbnail', 'updatedAt', 'fileName'), _dec22 = Ember.computed('date'), _dec23 = (0, _object.observes)('dateObject'), classic(_class = (_class2 = (_temp = class MyDocument extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appConfig", _descriptor, this);

      _initializerDefineProperty(this, "type", _descriptor2, this);

      _initializerDefineProperty(this, "title", _descriptor3, this);

      _initializerDefineProperty(this, "municipality", _descriptor4, this);

      _initializerDefineProperty(this, "properties", _descriptor5, this);

      _initializerDefineProperty(this, "referenceId", _descriptor6, this);

      _initializerDefineProperty(this, "date", _descriptor7, this);

      _initializerDefineProperty(this, "description", _descriptor8, this);

      _initializerDefineProperty(this, "keywords", _descriptor9, this);

      _initializerDefineProperty(this, "downloadRedirect", _descriptor10, this);

      _initializerDefineProperty(this, "fileName", _descriptor11, this);

      _initializerDefineProperty(this, "fileSize", _descriptor12, this);

      _initializerDefineProperty(this, "mimeType", _descriptor13, this);

      _initializerDefineProperty(this, "thumbnail", _descriptor14, this);

      _initializerDefineProperty(this, "geometries", _descriptor15, this);

      _initializerDefineProperty(this, "updatedAt", _descriptor16, this);

      _initializerDefineProperty(this, "updatedBy", _descriptor17, this);

      _initializerDefineProperty(this, "createdAt", _descriptor18, this);

      _initializerDefineProperty(this, "createdBy", _descriptor19, this);
    }

    get address() {
      var property = this.properties.objectAt(0);
      var address = property && property.get('address');
      var municipality = this.appConfig.config.multipleMunicipality && this.municipality;
      var fullAddress = [address, municipality].filter(x => x).join(', ');
      return fullAddress;
    }

    get thumbnailSrc() {
      var updatedAt = this.updatedAt;
      var thumbnail = this.thumbnail;
      var fileName = this.fileName;
      var src;

      if (thumbnail) {
        src = thumbnail + '?_=' + updatedAt;
      } else if (fileName) {
        src = '/images/no-preview.png';
      }

      return src;
    }

    get dateObject() {
      var date = this.date || null;
      return new JustDate(date).date;
    }

    onDateUpdated() {
      var dateObject = this.dateObject;
      var dateString = new JustDate(dateObject).toString();
      this.set('date', dateString || null);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "appConfig", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "type", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "title", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "municipality", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "properties", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "referenceId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "date", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "description", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "keywords", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "downloadRedirect", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "fileName", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "fileSize", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "mimeType", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "thumbnail", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "geometries", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "updatedAt", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class2.prototype, "updatedBy", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class2.prototype, "createdAt", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class2.prototype, "createdBy", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "address", [_dec20], Object.getOwnPropertyDescriptor(_class2.prototype, "address"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "thumbnailSrc", [_dec21], Object.getOwnPropertyDescriptor(_class2.prototype, "thumbnailSrc"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "dateObject", [_dec22], Object.getOwnPropertyDescriptor(_class2.prototype, "dateObject"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onDateUpdated", [_dec23], Object.getOwnPropertyDescriptor(_class2.prototype, "onDateUpdated"), _class2.prototype)), _class2)) || _class);

  window.__CLASSIC_OWN_CLASSES__.set(MyDocument, true);

  if (_environment.default.environment === 'offline') {
    MyDocument.reopenClass({
      FIXTURES: []
    });
  }

  var _default = MyDocument;
  _exports.default = _default;
});