define("sccog/components/my-form", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.promise this.submitting}}
  */
  {"id":"BBQWMxy9","block":"{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[32,0,[\"promise\"]],[32,0,[\"submitting\"]]]]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sccog/components/my-form.hbs"}});

  const classic = __EMBER_CLASSIC_DECORATOR;
  let MyForm = (_dec = (0, _component.tagName)('form'), classic(_class = _dec(_class = class MyForm extends Ember.Component {
    submit(event) {
      event.preventDefault();
      var promise = this['on-submit']();
      this.set('promise', promise);
    }

  }) || _class) || _class);
  _exports.default = MyForm;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MyForm);
});