define("sccog/utils/load-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(url, callback) {
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url; // There are several events for cross browser compatibility.

    script.onreadystatechange = callback;
    script.onload = callback;
    head.appendChild(script);
  }
});