define("sccog/pods/components/date-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cbdviDV2",
    "block": "{\"symbols\":[\"&attrs\",\"@inputClass\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[8,\"one-way-date-mask\",[],[[\"@value\",\"@options\",\"@update\",\"@class\"],[[32,0,[\"displayValue\"]],[30,[36,0],null,[[\"inputFormat\",\"outputFormat\"],[\"mm/dd/yyyy\",\"mm/dd/yyyy\"]]],[30,[36,1],[[32,0],\"onUpdate\"],null],[32,2]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"hash\",\"action\"]}",
    "meta": {
      "moduleName": "sccog/pods/components/date-field/template.hbs"
    }
  });

  _exports.default = _default;
});