define("sccog/components/document-tooltip", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span ...attributes>
    {{#if this.content}}
      <HelpTooltip @content={{this.content}} />
    {{/if}}
  </span>
  */
  {"id":"M1Zoz7fW","block":"{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"span\"],[17,1],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"content\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"help-tooltip\",[],[[\"@content\"],[[32,0,[\"content\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"sccog/components/document-tooltip.hbs"}});

  const classic = __EMBER_CLASSIC_DECORATOR;
  let DocumentTooltip = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.computed, classic(_class = _dec(_class = (_class2 = (_temp = class DocumentTooltip extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appConfig", _descriptor, this);
    }

    get content() {
      var attribute = this.attribute;
      var config = this.appConfig.config.documentAttributes[attribute] || {};
      return config.tooltip;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "appConfig", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "content", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "content"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = DocumentTooltip;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DocumentTooltip);
});