define("sccog/mixins/save-controller", ["exports", "sccog/utils/pace-monitor"], function (_exports, _paceMonitor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable ember/no-new-mixins
  var _default = Ember.Mixin.create({
    handleSaveError(err) {
      /* eslint no-console: 0 */
      console.error(err);

      _paceMonitor.default.done();

      if (err.errors) {
        let validationResult = err.errors; // TODO: there might be more validation errors sent from the server, so we need to handle more than one.

        let firstValidationError = validationResult[Object.keys(validationResult)[0]][0];
        this.toast.error('Validation error: ' + firstValidationError);
      } else if (err.message) {
        let message = err.message.indexOf('Ember Data') === 0 ? err.message.slice(11) : err.message;
        this.toast.error('Error: ' + message);
      } else {
        this.toast.error('Something went wrong. Please try again.');
      }
    }

  });

  _exports.default = _default;
});