define("sccog/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XZwISC88",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"page-header\"],[12],[2,\"\\n  Please sign in\\n\"],[13],[2,\"\\n\\nIt appears that you tried to access something without signing in. Please sign in first.\\n\"],[8,\"on-render\",[],[[\"@action\"],[[32,0,[\"openModal\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "sccog/pods/login/template.hbs"
    }
  });

  _exports.default = _default;
});