define("sccog/pods/sessionexpired/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zs9tpQAe",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"page-header\"],[12],[2,\"\\n  Session Expired\\n\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"\\n  It appears that your login session has expired. Please\\n  \"],[10,\"a\"],[14,6,\"#\"],[14,\"data-toggle\",\"modal\"],[14,\"data-target\",\"#login-modal\"],[12],[2,\"\\n    sign in\\n  \"],[13],[2,\"\\n  again.\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "sccog/pods/sessionexpired/template.hbs"
    }
  });

  _exports.default = _default;
});