define("sccog/utils/pace-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = window.app || {
    EmberMonitor: function () {}
  };
  var Pace = window.Pace || {
    restart: function () {}
  };
  /**
   * The existence of this module makes me a bit sad.
   *
   * The Pace library, which shows loading progress to the user, requires access
   * to a monitor class in order to inform the loading bar of progress. That access
   * has to be provided on startup, so we have to make it global. We call it EmberMonitor.
   *
   * Pace then calls `new EmberMonitor()`, and checks the instance's `progress`
   * property repeatedly. In order for us to update that private instance's
   * progress property, we update the property on the prototype using this module.
   */

  var _default = {
    start: function () {
      App.EmberMonitor.prototype.progress = 50;
      Pace.restart();
    },
    done: function () {
      App.EmberMonitor.prototype.progress = 100;
      Pace.stop();
    }
  };
  _exports.default = _default;
});