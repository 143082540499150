define("sccog/router", ["exports", "sccog/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;

  window.__CLASSIC_OWN_CLASSES__.set(Router, true);

  Router.map(function () {
    this.route('documents', {
      path: '/document',
      resetNamespace: true
    }, function () {
      this.route('new');
    });
    this.route('document', {
      path: '/document/:document_id',
      resetNamespace: true
    }, function () {
      this.route('edit');
      this.route('download');
      this.route('download-unavailable');
    });
    this.route('users', {
      path: '/user',
      resetNamespace: true
    }, function () {
      this.route('new');
    });
    this.route('user', {
      path: '/user/:user_id',
      resetNamespace: true
    }, function () {
      this.route('edit');
    });
    this.route('export');
    this.route('unauthorized');
    this.route('sessionexpired');
    this.route('myaccount');
    this.route('contact');
    this.route('login');
    this.route('page-not-found', {
      path: '/*wildcard'
    });
  });
});