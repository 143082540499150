define("sccog/utils/map-tip", ["exports", "jquery", "sccog/utils/ajax"], function (_exports, _jquery, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(options) {
    var $el = (0, _jquery.default)(`
    <div class="map-tip">
      <div class="map-tip-content"></div>
      <div class="map-tip-controls">
        Add: <a href="javascript:void(0)" data-action="addproperty">Property</a>
        <a href="javascript:void(0)" data-action="addgeometry">Parcel</a>
        <a href="javascript:void(0)" data-action="addboth">Both</a>
      </div>
    </div>
  `);
    var map = options.map;
    var enabled = true;
    var fadeTimeout = null;
    var queryTimeout = null;
    var tipNumber = 0;
    var clicked = false;
    var $links = $el.find('.map-tip-controls a');
    var $content = $el.find('.map-tip-content');
    $el.insertAfter(map.getContainer());
    map.on('zoom move', fadeTip);
    map.on('mousedown', function () {
      fadeTip();
      stopTip();
      clicked = true;
    });
    map.on('mouseout', function () {
      stopTip();
    });
    map.on('mousemove', function (e) {
      fadeTip();

      if (!clicked && enabled) {
        if (queryTimeout) {
          clearTimeout(queryTimeout);
        }

        queryTimeout = setTimeout(function () {
          var myNumber = tipNumber += 1;
          var latlng = e.latlng;
          getMapTip(latlng).then(function (result) {
            if (myNumber !== tipNumber || !result || !result.values) {
              return;
            } // Populate maptip content.


            var content = result.values.map(function (line) {
              return `<span>${line}</span>`;
            }).join('');
            $content.html(content);
            $links.data('propertyid', result.propertyid); // Position maptip.

            var p = map.latLngToContainerPoint(latlng);
            var top = p.y - $el.height();
            $el.css({
              left: p.x,
              top: top
            }).fadeTo(250, 1);
            fadeTimeout = null;
          });
        }, 1000);
      }

      clicked = false;
    });
    $el.on('mouseenter', function () {
      if (fadeTimeout) {
        clearTimeout(fadeTimeout);
        fadeTimeout = null;
      }
    });
    $el.on('mouseleave', function () {
      fadeTip();
    });
    $links.on('click', function (e) {
      var $target = (0, _jquery.default)(e.target);
      var propertyId = $target.data('propertyid');
      var action = $target.data('action');
      var mapEvent = `maptip:${action}`;
      getProperty(propertyId).then(function (property) {
        map.fire(mapEvent, {
          property: property
        });
      });
      fadeTip();
    });

    function fadeTip() {
      if (!fadeTimeout) {
        fadeTimeout = setTimeout(function () {
          $el.fadeOut(250);
          fadeTimeout = null;
        }, 250);
      }
    }

    function stopTip() {
      if (queryTimeout) {
        clearTimeout(queryTimeout);
        queryTimeout = null;
        tipNumber += 1;
      }
    }

    var mapTip = {
      enable: function () {
        enabled = true;
      },
      disable: function () {
        enabled = false;
      }
    };
    return mapTip;
  }

  function getMapTip(latLng) {
    return (0, _ajax.default)('/api/mapgeo/maptip', {
      data: {
        lat: latLng.lat,
        lng: latLng.lng
      }
    });
  }

  function getProperty(propertyId) {
    return (0, _ajax.default)('/api/mapgeo/property', {
      data: {
        id: propertyId
      }
    });
  }
});