define("sccog/initializers/anonymous-check", ["exports", "sccog/utils/app-config"], function (_exports, _appConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var denyAnonymous = !_appConfig.default.enableAnonymousAccess;
  var _default = {
    name: 'anonymous-check',

    initialize() {
      // Override `beforeModel` to check for anonymous access.
      Ember.Route.reopen({
        session: Ember.inject.service(),

        beforeModel(transition) {
          this._super(transition);

          var loggedIn = !!this.get('session.role');
          var isLoginRoute = transition.targetName === 'login';

          if (!loggedIn && denyAnonymous && !isLoginRoute) {
            this.session.set('attemptedTransition', transition);
            this.transitionTo('login');
          }
        }

      });
    }

  };
  _exports.default = _default;
});