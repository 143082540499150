define("sccog/pods/export/route", ["exports", "sccog/mixins/reset-scroll", "sccog/utils/dropdown-data"], function (_exports, _resetScroll, _dropdownData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let ExportRoute = classic(_class = class ExportRoute extends Ember.Route.extend(_resetScroll.default) {
    model() {
      return Ember.RSVP.hash({
        municipalities: (0, _dropdownData.getUsedMunicipalities)()
      });
    }

    setupController(controller, model) {
      controller.set('municipalities', model.municipalities);
    }

  }) || _class;

  _exports.default = ExportRoute;
});