define("sccog/pods/documents/index/route", ["exports", "sccog/mixins/reset-scroll", "sccog/utils/dropdown-data"], function (_exports, _resetScroll, _dropdownData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let IndexRoute = (_dec = Ember._action, classic(_class = (_class2 = (_temp = class IndexRoute extends Ember.Route.extend(_resetScroll.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        q: {
          refreshModel: true
        },
        title: {
          refreshModel: true
        },
        referenceId: {
          refreshModel: true
        },
        type: {
          refreshModel: true
        },
        municipality: {
          refreshModel: true
        },
        description: {
          refreshModel: true
        },
        keywords: {
          refreshModel: true
        },
        startDate: {
          refreshModel: true
        },
        endDate: {
          refreshModel: true
        },
        fileName: {
          refreshModel: true
        },
        propertyId: {
          refreshModel: true
        },
        address: {
          refreshModel: true
        },
        owner: {
          refreshModel: true
        },
        page: {
          refreshModel: true
        },
        lat: {
          refreshModel: true
        },
        lng: {
          refreshModel: true
        },
        radius: {
          refreshModel: true
        },
        orPropertyId: {
          refreshModel: true
        },
        orderBy: {
          refreshModel: true
        },
        orderDir: {
          refreshModel: true
        }
      });
    }

    model(params) {
      var documents = this.store.query('document', params); // Get types and municipalities asynchronously.

      var documentTypes = (0, _dropdownData.getTypes)();
      var municipalities = (0, _dropdownData.getUsedMunicipalities)(); // Return a hash of promises. Handle the hash of resolved stuff in `setupController`.
      // We have to do the lookups here instead of in `setupController` because this function
      // is meant to return a promise, and `setupController` is not.

      return Ember.RSVP.hash({
        model: documents,
        documentTypes: documentTypes,
        municipalities: municipalities
      });
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    } // Reset the search form when the model changes, or the route exits.


    resetController(controller) {
      controller.clearSearchParams();
    }

    clearSearch() {
      var controller = this.controllerFor('documents.index'); // Clear the search parameters right away, as they aren't reset when changing the model.
      // In the UI, this means that the components that rely on search parameters will update before the model does.

      controller.clearSearchParams(); // Reset the controller with a fresh model, fetched with no params.

      this.model({}).then(function (resolved) {
        controller.set('model', resolved.model);
      });
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "clearSearch", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "clearSearch"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexRoute;
});