define("sccog/utils/app-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-get
  var config = Ember.get(window, 'app.config') || {};
  var mg = config.mapgeoConfig;

  if (mg && !mg.url) {
    config.mapgeoConfig.url = `${mg.protocol}://${mg.communitySubdomain}.${mg.base}`;
  }

  var _default = config;
  _exports.default = _default;
});