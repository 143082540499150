define("sccog/components/link-li", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {"id":"ucbta1FJ","block":"{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sccog/components/link-li.hbs"}});

  const classic = __EMBER_CLASSIC_DECORATOR;
  let LinkLi = (_dec = (0, _component.tagName)('li'), _dec2 = (0, _component.classNameBindings)('active'), _dec3 = Ember.computed('childViews.@each.active'), classic(_class = _dec(_class = _dec2(_class = (_class2 = class LinkLi extends Ember.Component {
    get active() {
      return this.childViews.isAny('active');
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "active", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "active"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = LinkLi;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LinkLi);
});