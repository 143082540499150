define("sccog/torii-providers/google", ["exports", "torii/providers/oauth2-code", "torii/configuration", "sccog/utils/ajax", "sccog/utils/get-cookies"], function (_exports, _oauth2Code, _configuration, _ajax, _getCookies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let GoogleOauth2 = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _configuration.configurable)('requestVisibleActions', ''), _dec4 = (0, _configuration.configurable)('accessType', 'offline'), _dec5 = (0, _configuration.configurable)('scope', 'email'), _dec6 = (0, _configuration.configurable)('approvalPrompt', 'auto'), _dec7 = (0, _configuration.configurable)('redirectUri', function () {
    let config = this.get('appConfig.config.mapgeoConfig');
    return `${config.protocol}://oauth.${config.base}/google`;
  }), _dec8 = (0, _configuration.configurable)('hd', ''), classic(_class = (_class2 = (_temp = class GoogleOauth2 extends _oauth2Code.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "appConfig", _descriptor2, this);

      _defineProperty(this, "name", 'google');

      _defineProperty(this, "baseUrl", 'https://accounts.google.com/o/oauth2/auth');

      _defineProperty(this, "optionalUrlParams", ['scope', 'request_visible_actions', 'access_type', 'approval_prompt', 'hd']);

      _initializerDefineProperty(this, "requestVisibleActions", _descriptor3, this);

      _initializerDefineProperty(this, "accessType", _descriptor4, this);

      _defineProperty(this, "responseParams", ['code', 'state']);

      _initializerDefineProperty(this, "scope", _descriptor5, this);

      _initializerDefineProperty(this, "approvalPrompt", _descriptor6, this);

      _initializerDefineProperty(this, "redirectUri", _descriptor7, this);

      _initializerDefineProperty(this, "hd", _descriptor8, this);
    }

    get state() {
      return `${this.appConfig.config.mapgeoConfig.communitySubdomain}:docs`;
    }

    fetch(data) {
      let cookies = (0, _getCookies.default)();
      let config = this.get('appConfig.config.mapgeoConfig');
      let accessToken = cookies.accessToken || data.accessToken;
      let headers = {
        'Access-Token': accessToken
      };
      delete data.accessToken;
      return (0, _ajax.default)(`${config.url}/api/oauth/restore`, {
        data,
        headers,
        xhrFields: {
          withCredentials: true
        }
      }) // Make sure session is invalidated if restore fails
      // handled in services/session
      // Don't reject/throw or torii wont invalidate properly
      .catch(error => {
        let session = this.session;
        session.set('googleRestoreError', error); // eslint-disable-next-line no-console

        console.error(error);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appConfig", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "requestVisibleActions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "accessType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "scope", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "approvalPrompt", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "redirectUri", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "hd", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);

  window.__CLASSIC_OWN_CLASSES__.set(GoogleOauth2, true);

  var _default = GoogleOauth2;
  _exports.default = _default;
});