define("sccog/pods/components/date-field/component", ["exports", "@ember-decorators/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let DateField = (_dec = (0, _component.tagName)(''), _dec2 = Ember._action, _dec(_class = classic(_class = (_class2 = class DateField extends Ember.Component {
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);

      if (this.value) {
        this.set('displayValue', new _moment.default(this.value).format('MM/DD/YYYY'));
      }
    }

    onUpdate(dateString) {
      if (!dateString.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/)) {
        return this.onChange(null);
      }

      if (isNaN(new Date(dateString).getTime())) {
        return this.onChange(null);
      }

      this.onChange(new Date(dateString));
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "onUpdate", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "onUpdate"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = DateField;
});