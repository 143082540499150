define("sccog/components/search-params", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='search-params' ...attributes>
    <div>
      {{#if this.hasParams}}
        Searching for
        {{#each this.paramStrings as |str|}}
          <span class='label label-default'>
            {{str}}
          </span>
        {{/each}}
        &nbsp;&nbsp;
        <a href='javascript:void(0)' {{action 'clear'}}>
          Clear
        </a>
      {{/if}}
    </div>
  </div>
  */
  {"id":"pD/SGwv6","block":"{\"symbols\":[\"str\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"search-params\"],[17,2],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"hasParams\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      Searching for\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"paramStrings\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"label label-default\"],[12],[2,\"\\n          \"],[1,[32,1]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \\n      \"],[11,\"a\"],[24,6,\"javascript:void(0)\"],[4,[38,2],[[32,0],\"clear\"],null],[12],[2,\"\\n        Clear\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"action\",\"if\"]}","meta":{"moduleName":"sccog/components/search-params.hbs"}});

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SearchParams = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('params'), _dec3 = Ember.computed('params'), _dec4 = Ember._action, _dec(_class = classic(_class = (_class2 = class SearchParams extends Ember.Component {
    get hasParams() {
      var params = this.params;
      var hasParams = false;

      for (var i in params) {
        if (params[i]) {
          hasParams = true;
          break;
        }
      }

      return hasParams;
    } // Create an array of parameter strings in the form of "key: value".
    // If the parameter key matches `this.basicParam`, then omit the "key: " part.


    get paramStrings() {
      var params = this.params;
      var basicParam = this.basicParam;
      var paramStrings = [];

      for (var i in params) {
        var str;

        if (params[i]) {
          if (i === basicParam) {
            str = params[i];
          } else {
            str = i + ': ' + params[i];
          }

          paramStrings.push(str);
        }
      }

      return paramStrings;
    }

    clear() {
      // Calling this action with the expectation that someone else will clear the `params` object,
      // since this component doesn't own the `params` object, but gets one passed in.
      this.onClear();
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "hasParams", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "hasParams"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "paramStrings", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "paramStrings"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clear", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "clear"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = SearchParams;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SearchParams);
});