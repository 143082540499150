define("sccog/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param value {string} date as an ISO string, not including the time (YYYY-MM-DD).
   */
  var _default = Ember.Helper.helper(function ([value]) {
    var dateString = '';

    try {
      dateString = new JustDate(value).toFormattedString();
      return dateString;
    } catch (err) {// noop
    }

    try {
      var date = new Date(value);
      dateString = new JustDate(date).toFormattedString();
      return dateString;
    } catch (err) {// noop
    }

    return dateString;
  });

  _exports.default = _default;
});