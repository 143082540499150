define("sccog/pods/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "sccog/utils/ajax", "sccog/utils/get-cookies"], function (_exports, _applicationRouteMixin, _ajax, _getCookies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let ApplicationRoute = (_dec = Ember.inject.service('session'), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, classic(_class = (_class2 = (_temp = class ApplicationRoute extends Ember.Route.extend(_applicationRouteMixin.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "appConfig", _descriptor2, this);
    }

    beforeModel() {
      // Check login from passed on token
      let cookies = (0, _getCookies.default)();
      let mgConfig = this.get('appConfig.config.mapgeoConfig');
      let authConfig = this.get('appConfig.config.auth');
      let accessToken = cookies.accessToken || authConfig && authConfig.accessToken;

      if (!accessToken) {
        return;
      }

      let session = this.session;
      let headers = {
        'Access-Token': accessToken
      };
      let data = {
        subdomain: mgConfig.communitySubdomain
      };
      return (0, _ajax.default)(`${mgConfig.url}/api/oauth/restore`, {
        headers,
        data,
        xhrFields: {
          withCredentials: true
        }
      }).then(res => {
        return session.loadDocsData(res);
      }).catch(() => {
        return Ember.RSVP.resolve();
      });
    }

    setupController(controller, resolved) {
      super.setupController(controller, resolved);
      controller.set('accessToken', '');
    }

    invalidateSession() {
      let session = this.session;
      let config = this.get('appConfig.config.mapgeoConfig');
      let domain = config ? `;domain=${config.communitySubdomain}.${config.base}` : '';
      let secure = config && config.protocol === 'https' ? ';secure' : '';
      session.invalidate(); // clear the cookie

      document.cookie = `jwttoken=${domain}${secure}`;
      document.cookie = `userId=${domain}${secure}`;
      document.cookie = `accessToken=${domain}${secure}`;
    }

    sessionUnauthorized() {
      this.transitionTo('unauthorized');
    }

    authorizationFailed() {
      var isAuthenticated = this.session;

      if (isAuthenticated) {
        var expiration = this.get('session.data.authenticated.exp');
        var isExpired = !expiration || expiration * 1000 < Date.now();

        if (isExpired) {
          this.session.invalidate().then(function () {
            // This is a little hokey, but the application route does the same
            // by default when the session is invalidated. This allows us to keep
            // that behavior by default, but behave differently here.
            window.location.replace('/sessionexpired');
          });
        } else {
          this.transitionTo('unauthorized');
        }
      } else {
        this.transitionTo('login');
      }
    }

    loginSucceeded() {
      this.transitionTo('index');
    } // override application-route-mixin's method
    // super doesn't seem to work..


    sessionAuthenticated() {
      let session = this.session;
      session.loadDocsData().then(() => {
        let attemptedTransition = this.get('session.attemptedTransition');

        if (attemptedTransition) {
          attemptedTransition.retry();
          this.set('session.attemptedTransition', null);
        } else {
          this.transitionTo('index');
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "appConfig", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "invalidateSession", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "invalidateSession"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sessionUnauthorized", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "sessionUnauthorized"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "authorizationFailed", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "authorizationFailed"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loginSucceeded", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "loginSucceeded"), _class2.prototype)), _class2)) || _class);
  _exports.default = ApplicationRoute;
});