define("sccog/pods/document/download-unavailable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t/pH9mje",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"page-header\"],[12],[2,\"\\n  Download Unavailable\\n\"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"model\",\"downloadRedirect\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"\\n    This document is not available for download by unauthorized users.\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    Please visit your city or town's external document repository to obtain a copy of the document.\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[32,0,[\"model\",\"downloadRedirect\"]]],[14,\"target\",\"_blank\"],[14,0,\"btn btn-primary\"],[12],[2,\"\\n      Go to\\n      \"],[10,\"strong\"],[12],[2,\"\\n        \"],[1,[32,0,[\"model\",\"downloadRedirect\"]]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"i\"],[14,0,\"fa fa-external-link\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"\\n    This document is not available for download by unauthorized users. Please sign in.\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[10,\"a\"],[14,6,\"#\"],[14,\"data-toggle\",\"modal\"],[14,\"data-target\",\"#login-modal\"],[14,0,\"btn btn-primary\"],[12],[2,\"\\n      Sign in\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\\n\"],[2,[32,0,[\"appConfig\",\"config\",\"contactHtml\"]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "sccog/pods/document/download-unavailable/template.hbs"
    }
  });

  _exports.default = _default;
});