define("sccog/components/property-search", ["exports", "@ember-decorators/component", "jquery", "sccog/utils/ajax", "sccog/utils/app-config"], function (_exports, _component, _jquery, _ajax, _appConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    <div class='property-search'>
      Add a new property:
      <a href='javascript:void(0)' class='search-button'>
        Search
      </a>
      or
      <button type='button' class='btn btn-link btn-sm' {{on 'click' @add}}>
        Input Manually
      </button>
    </div>
  </div>
  */
  {"id":"q9sOI4LG","block":"{\"symbols\":[\"&attrs\",\"@add\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"property-search\"],[12],[2,\"\\n    Add a new property:\\n    \"],[10,\"a\"],[14,6,\"javascript:void(0)\"],[14,0,\"search-button\"],[12],[2,\"\\n      Search\\n    \"],[13],[2,\"\\n    or\\n    \"],[11,\"button\"],[24,0,\"btn btn-link btn-sm\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n      Input Manually\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\"]}","meta":{"moduleName":"sccog/components/property-search.hbs"}});

  const classic = __EMBER_CLASSIC_DECORATOR;
  var multipleMunicipality = _appConfig.default.multipleMunicipality;
  let PropertySearch = (_dec = (0, _component.tagName)(''), classic(_class = _dec(_class = (_temp = class PropertySearch extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "municipality", null);
    }

    getMapgeoMunicipality() {
      var muni = this.municipality;
      var justMuni = muni && muni.split(',')[0];
      return justMuni;
    }
    /**
     * Initialize the popover. This is called once when the component is added to the DOM.
     */


    didInsertElement() {
      var self = this;
      var $searchButton = (0, _jquery.default)('.search-button'); // Setup popover for search.

      $searchButton.popover({
        container: '.property-search',
        content: popoverContent.bind(this),
        html: true,
        placement: 'top',
        trigger: 'click'
      }); // Do some stuff when popover appears.

      $searchButton.on('shown.bs.popover', function () {
        var $input = self.$('#property-search-input');

        function source(query, cb) {
          Ember.run.debounce(null, mapgeoPropertySearch, query, self.getMapgeoMunicipality(), cb, 300);
        } // Setup the typeahead plugin on the input.


        $input.typeahead({
          minLength: 3
        }, {
          source: source,
          templates: {
            empty: '<div class="empty-results">No results</div>',
            suggestion: suggestionTemplate
          }
        }); // When a property is selected, hide the popover, and send the property along via the `add` action.

        $input.on('typeahead:selected', (e, property) => {
          $searchButton.popover('hide');
          this.add(property);
        }); // Bring focus to the input.

        $input.focus(); // Handle special keypresses on the input.
        // Note: need to listen to `keyup` to capture ESC, and `keypress` to correctly prevent bubling and a form submission on ENTER.

        $input.on('keyup keypress', function (e) {
          if (e.which === 27) {
            // ESC: close popover.
            $searchButton.popover('hide');
          } else if (e.which === 13) {
            // ENTER: do nothing and prevent bubbling.
            e.preventDefault();
            return false;
          }
        }); // Unfocusing the input will make the popover close.

        $input.on('blur', function () {
          $searchButton.popover('hide');
        });
      });
    }

  }, _temp)) || _class) || _class);
  _exports.default = PropertySearch;

  function mapgeoPropertySearch(query, municipality, callback) {
    var url = '/api/mapgeo/properties';
    var options = {
      data: {
        q: query,
        municipality: municipality
      }
    };
    (0, _ajax.default)(url, options).then(function (result) {
      if (!result || !result.properties) {
        onError();
      }

      callback(result.properties);
    }).catch(onError);

    function onError() {
      callback();
    }
  }
  /**
   * Poor man's handlebars template for search suggestions.
   */


  function suggestionTemplate(property) {
    let muni = property.municipality ? ', ' + property.municipality : '';
    let owner = property.owner ? `, ${property.owner}` : '';
    return `
  <p>
    <span class="title">${property.address}${muni}</span>
    <span class="details">${property.propertyId}${owner}</span>
  </p>`;
  }
  /**
   * Returns a string representing the popover content.
   * Must be bound to the component, since some of the values used on `this` are dynamic (can't just be scoped).
   */


  function popoverContent() {
    var muni = this.municipality;
    var content = '<input type="text" class="form-control" id="property-search-input" /></div>';

    if (multipleMunicipality && muni) {
      content = `<span class="municipality">Searching within ${muni}</span>${content}`;
    }

    return content;
  }

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PropertySearch);
});