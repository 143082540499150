define("sccog/serializers/document", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Document = classic(_class = class Document extends _rest.default {
    constructor() {
      super(...arguments);
      this.attrs = {
        downloadRedirect: {
          serialize: false
        }
      };
      this.isNewSerializerAPI = true;
    }

  }) || _class;

  _exports.default = Document;
});