define("sccog/pods/user/edit/route", ["exports", "sccog/utils/dropdown-data", "sccog/mixins/authenticated-route", "sccog/mixins/reset-scroll"], function (_exports, _dropdownData, _authenticatedRoute, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let EditRoute = classic(_class = (_temp = class EditRoute extends Ember.Route.extend(_authenticatedRoute.default, _resetScroll.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "roles", ['admin']);
    }

    model() {
      let user = this.modelFor('user');
      let roles = (0, _dropdownData.getRoles)();
      let municipalities = (0, _dropdownData.getMunicipalities)(); // Return a hash of promises. Handle the hash of resolved stuff in `setupController`.
      // We have to do the lookups here instead of in `setupController` because this function
      // is meant to return a promise, and `setupController` is not.

      return Ember.RSVP.hash({
        user,
        roles,
        municipalities
      });
    }

    setupController(controller, model) {
      controller.setProperties({
        model: model.user,
        roles: model.roles,
        municipalities: model.municipalities
      });
    }

    deactivate() {
      let model = this.modelFor('user.edit');
      model.user.rollbackAttributes();
    }

  }, _temp)) || _class;

  _exports.default = EditRoute;
});