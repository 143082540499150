define("sccog/pods/documents/index/controller", ["exports", "jquery", "sccog/utils/without"], function (_exports, _jquery, _without) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  var roleLevels = {
    anonymous: 0,
    viewer: 1,
    editor: 2,
    admin: 3
  }; // NOTE: This array needs to be kept in sync with the properties specified in 'searchParams'.

  var QUERY_PARAMS = ['q', 'title', 'referenceId', 'type', 'municipality', 'description', 'keywords', 'startDate', 'endDate', 'fileName', 'propertyId', 'address', 'owner', 'lat', 'lng', 'radius', 'orPropertyId', 'page', 'orderBy', 'orderDir'];
  var FILTER_PARAMS = (0, _without.default)(QUERY_PARAMS, 'page', 'orderBy', 'orderDir');
  var DEFAULT_PARAMS = {
    page: 1,
    orderBy: 'title',
    orderDir: 'asc'
  };
  let IndexController = (_dec = Ember.inject.service, _dec2 = Ember.computed('session.role'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember.computed('q', 'title', 'referenceId', 'type', 'municipality', 'description', 'keywords', 'startDate', 'endDate', 'fileName', 'propertyId', 'address', 'owner', 'lat', 'lng', 'radius', 'orPropertyId', 'orderBy', 'orderDir'), _dec24 = Ember.computed('model'), _dec25 = Ember.computed('page', 'model.meta.pages'), _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, classic(_class = (_class2 = (_temp = class IndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appConfig", _descriptor, this);

      _defineProperty(this, "queryParams", QUERY_PARAMS);

      _initializerDefineProperty(this, "q", _descriptor2, this);

      _initializerDefineProperty(this, "title", _descriptor3, this);

      _initializerDefineProperty(this, "referenceId", _descriptor4, this);

      _initializerDefineProperty(this, "type", _descriptor5, this);

      _initializerDefineProperty(this, "municipality", _descriptor6, this);

      _initializerDefineProperty(this, "description", _descriptor7, this);

      _initializerDefineProperty(this, "keywords", _descriptor8, this);

      _initializerDefineProperty(this, "startDate", _descriptor9, this);

      _initializerDefineProperty(this, "endDate", _descriptor10, this);

      _initializerDefineProperty(this, "fileName", _descriptor11, this);

      _initializerDefineProperty(this, "propertyId", _descriptor12, this);

      _initializerDefineProperty(this, "address", _descriptor13, this);

      _initializerDefineProperty(this, "owner", _descriptor14, this);

      _initializerDefineProperty(this, "lat", _descriptor15, this);

      _initializerDefineProperty(this, "lng", _descriptor16, this);

      _initializerDefineProperty(this, "radius", _descriptor17, this);

      _initializerDefineProperty(this, "orPropertyId", _descriptor18, this);

      _initializerDefineProperty(this, "page", _descriptor19, this);

      _initializerDefineProperty(this, "orderBy", _descriptor20, this);

      _initializerDefineProperty(this, "orderDir", _descriptor21, this);

      _defineProperty(this, "documentTypes", []);

      _defineProperty(this, "municipalities", []);

      _defineProperty(this, "searchOpen", false);

      _defineProperty(this, "sortColumns", [{
        value: 'title',
        text: label('title', this.appConfig)
      }, {
        value: 'type',
        text: label('type', this.appConfig)
      }, {
        value: 'address',
        text: 'Address'
      }, {
        value: 'date',
        text: label('date', this.appConfig)
      }, {
        value: 'reference_id',
        text: label('referenceId', this.appConfig)
      }, {
        value: 'created_at',
        text: 'Created'
      }]);

      _defineProperty(this, "sortDirections", [{
        value: 'asc',
        text: 'ascending'
      }, {
        value: 'desc',
        text: 'descending'
      }]);
    }

    get userCanEdit() {
      var role = this.get('session.role');
      var roleLevel = roleLevels[role];
      return roleLevel >= roleLevels.editor;
    } // Bind query string param to controller property.


    /**
     * Search params, minus non-filter params like 'orderBy' and 'orderDir'.
     * Used by the search-params component (shows current filters).
     */
    get filterParams() {
      var searchParams = this.searchParams;
      delete searchParams['orderBy'];
      delete searchParams['orderDir'];
      return searchParams;
    }
    /**
     * Create a simple dictionary of params based on the search parameters in the queryParams array.
     * Used by the search-form component, and does not include `page`.
     */


    get searchParams() {
      var self = this;
      var queryParams = this.queryParams;
      var params = queryParams.reduce(function (result, param) {
        if (param !== 'page' && self.get(param)) {
          result[param] = self.get(param);
        }

        return result;
      }, {});
      return params;
    }
    /**
     * Given to the pagination component for rendering the results for different pages.
     */


    get currentUrl() {
      var params = this.searchParams;
      return '/document?' + _jquery.default.param(params) + '&page={current}';
    }

    get documentCountText() {
      var page = this.page;
      var display = this.get('model.meta.recordsDisplay');
      var total = this.get('model.meta.recordsTotal');
      var length = this.get('model.meta.length');

      if (total === 0) {
        return 'No documents found';
      }

      var start = (page - 1) * length + 1;
      var end = start + display - 1;
      return 'Showing documents ' + start.toLocaleString() + '-' + end.toLocaleString() + ' of ' + total.toLocaleString();
    }

    get documentPagerText() {
      var page = this.page;
      var pages = this.get('model.meta.pages');
      return 'Page ' + page.toLocaleString() + ' of ' + pages.toLocaleString();
    } // Reset all filter params.


    clearSearchParams() {
      var self = this; // Reset filter params, leaving sorting and paging alone.

      FILTER_PARAMS.forEach(function (param) {
        self.set(param, null);
      }); // Go back to page 1.

      this.set('page', 1);
    } // Called when the user saves their search filters.


    submitSearch(params) {
      // These are the defaults for searches when filters change.
      var baseParams = {
        page: 1,
        orderBy: this.orderBy,
        orderDir: this.orderDir
      }; // Build a query params object with null for undefined params,
      // and values from `params` for the rest.

      var queryParamsObject = FILTER_PARAMS.reduce(function (result, param) {
        result[param] = params[param] || null;
        return result;
      }, baseParams); // Set location search parameters, since `params` is always going to have undefined values for these.
      // Eventually, filter params will get controls for these params, and this won't be necessary.

      queryParamsObject.lat = this.lat;
      queryParamsObject.lng = this.lng;
      queryParamsObject.radius = this.radius;
      queryParamsObject.orPropertyId = this.orPropertyId;
      this.transitionToRoute({
        queryParams: queryParamsObject
      });
    }

    changeOrderBy(value) {
      this.set('orderBy', value);
      this.set('page', 1);
    }

    changeOrderDir(value) {
      this.set('orderDir', value);
      this.set('page', 1);
    }

    toggleSearchOpen(open) {
      this.set('searchOpen', open);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "appConfig", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "userCanEdit", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "userCanEdit"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "q", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "title", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "referenceId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "type", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "municipality", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "description", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "keywords", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "startDate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "endDate", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "fileName", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "propertyId", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "address", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "owner", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "lat", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "lng", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class2.prototype, "radius", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class2.prototype, "orPropertyId", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class2.prototype, "page", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_PARAMS.page;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class2.prototype, "orderBy", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_PARAMS.orderBy;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class2.prototype, "orderDir", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_PARAMS.orderDir;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "searchParams", [_dec23], Object.getOwnPropertyDescriptor(_class2.prototype, "searchParams"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "documentCountText", [_dec24], Object.getOwnPropertyDescriptor(_class2.prototype, "documentCountText"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "documentPagerText", [_dec25], Object.getOwnPropertyDescriptor(_class2.prototype, "documentPagerText"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "submitSearch", [_dec26], Object.getOwnPropertyDescriptor(_class2.prototype, "submitSearch"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeOrderBy", [_dec27], Object.getOwnPropertyDescriptor(_class2.prototype, "changeOrderBy"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeOrderDir", [_dec28], Object.getOwnPropertyDescriptor(_class2.prototype, "changeOrderDir"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleSearchOpen", [_dec29], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleSearchOpen"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexController;

  function label(attribute, appConfig) {
    var config = appConfig.config.documentAttributes[attribute] || {};
    return config.label || attribute;
  }
});