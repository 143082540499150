define("sccog/pods/unauthorized/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HTuFMewv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"page-header\"],[12],[2,\"\\n  Unauthorized\\n\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"\\n  It appears that you tried to access something you don't have access to.\\n\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"session\",\"role\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Your current user role is\\n    \"],[10,\"b\"],[12],[2,\"\\n      \"],[1,[32,0,[\"session\",\"role\"]]],[2,\"\\n    \"],[13],[6,[37,0],[[32,0,[\"session\",\"town\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n      and your municipality is\\n      \"],[10,\"b\"],[12],[2,\"\\n        \"],[1,[32,0,[\"session\",\"town\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    .\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    If you have an account here, please\\n    \"],[10,\"a\"],[14,6,\"#\"],[14,\"data-toggle\",\"modal\"],[14,\"data-target\",\"#login-modal\"],[12],[2,\"\\n      sign in\\n    \"],[13],[2,\"\\n    first.\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "sccog/pods/unauthorized/template.hbs"
    }
  });

  _exports.default = _default;
});