define("sccog/components/link-dropdown", ["exports", "@ember-decorators/component", "sccog/utils/find"], function (_exports, _component, _find) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='link-dropdown' ...attributes>
    <div class='btn-group'>
      <button
        type='button'
        class='btn btn-link dropdown-toggle'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        {{this.selected.text}}
      </button>
      <ul class='dropdown-menu dropdown-menu-right'>
        {{#each this.options as |option|}}
          <li>
            <a href='#' {{action 'clickOption' option}}>
              {{option.text}}
            </a>
          </li>
        {{/each}}
      </ul>
    </div>
  </div>
  */
  {"id":"0gSlqFLw","block":"{\"symbols\":[\"option\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"link-dropdown\"],[17,2],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"btn-group\"],[12],[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-link dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[14,\"aria-haspopup\",\"true\"],[14,\"aria-expanded\",\"false\"],[14,4,\"button\"],[12],[2,\"\\n      \"],[1,[32,0,[\"selected\",\"text\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"dropdown-menu dropdown-menu-right\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[2,\"\\n          \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"clickOption\",[32,1]],null],[12],[2,\"\\n            \"],[1,[32,1,[\"text\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"-track-array\",\"each\"]}","meta":{"moduleName":"sccog/components/link-dropdown.hbs"}});

  const classic = __EMBER_CLASSIC_DECORATOR;
  let LinkDropdown = (_dec = (0, _component.tagName)(''), _dec2 = Ember._action, _dec(_class = classic(_class = (_class2 = (_temp = class LinkDropdown extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "options", []);

      _defineProperty(this, "value", null);

      _defineProperty(this, "selected", null);
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.updateSelected();
    } // Set the selected option based on the value.


    updateSelected() {
      var value = this.value;
      var options = this.options;

      if (!value) {
        return;
      }

      var selected = (0, _find.default)(options, {
        value: value
      });
      this.set('selected', selected);
    } // Triggered when the user selects an option from the dropdown.


    clickOption(option) {
      this.set('selected', option);
      this.onchange(option.value);
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "clickOption", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "clickOption"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = LinkDropdown;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LinkDropdown);
});