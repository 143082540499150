define("sccog/pods/page-not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ECNpNmQI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"page-header\"],[12],[2,\"\\n  Page Not Found\\n\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"\\n  Sorry the page\\n  \"],[10,\"code\"],[12],[2,\"\\n    /\"],[1,[32,0,[\"model\",\"wildcard\"]]],[2,\"\\n  \"],[13],[2,\"\\n  was not found.\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "sccog/pods/page-not-found/template.hbs"
    }
  });

  _exports.default = _default;
});