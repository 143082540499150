define("sccog/components/file-upload", ["exports", "@ember-decorators/component", "ember-uploader/components/file-field", "ember-uploader/uploaders/uploader"], function (_exports, _component, _fileField, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {"id":"fw2b3guR","block":"{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sccog/components/file-upload.hbs"}});

  const classic = __EMBER_CLASSIC_DECORATOR;
  let FileUpload = (_dec = (0, _component.classNames)('document-file'), classic(_class = _dec(_class = class FileUpload extends _fileField.default {
    filesDidChange(files) {
      let url = this.url;

      let uploader = _uploader.default.create({
        url,
        method: 'PUT'
      });

      if (files && files.length) {
        this.uploadready(url => {
          if (url) {
            uploader.set('url', url);
          }

          return uploader.upload(files[0]).then(() => {
            let $el = this.element;
            clearFileInput($el);
          });
        });
      }
    }

  }) || _class) || _class);
  _exports.default = FileUpload;

  /**
   * Clear a file input element.
   */
  function clearFileInput(f) {
    if (f.value) {
      try {
        // For IE11, latest Chrome/Firefox/Opera
        f.value = '';
      } catch (_) {// noop
      }

      if (f.value) {
        // For IE5-10
        var form = document.createElement('form');
        var ref = f.nextSibling;
        form.appendChild(f);
        form.reset();
        ref.parentNode.insertBefore(f, ref);
      }
    }
  }

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FileUpload);
});