define("sccog/session-stores/application", ["exports", "jquery", "ember-simple-auth/session-stores/base", "ember-simple-auth/utils/objects-are-equal"], function (_exports, _jquery, _base, _objectsAreEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let Application =
  /*
    Overridden with basically the defaults due to iOS issues with
    private browsing. See the discussion in this PR
    https://github.com/simplabs/ember-simple-auth/pull/647
  */

  /**
    Store that saves its data in the browser's `localStorage`.
    _The factory for this store is registered as
    `'simple-auth-session-store:local-storage'` in Ember's container._
    __`Stores.LocalStorage` is Ember Simple Auth's default store.__
    @class LocalStorage
    @namespace SimpleAuth.Stores
    @module simple-auth/stores/local-storage
    @extends Stores.Base
  */
  classic(_class = (_temp = class Application extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "key", 'ember_simple_auth:session');
    }

    /**
      @method init
      @private
    */
    init() {
      this.bindToStorageEvents();
    }
    /**
      Persists the `data` in the `localStorage`.
      @method persist
      @param {Object} data The data to persist
    */


    persist(data) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        data = JSON.stringify(data || {}); // Prevent certain iOS Safari versions from crashing
        // in private browsing mode http://stackoverflow.com/a/14555361/483616

        try {
          localStorage.setItem(this.key, data);
        } catch (e) {
          /* eslint-disable no-console */
          console.warn('Data not saved to localStorage due to limitations of this browser, see http://stackoverflow.com/a/14555361/483616');
          /* eslint-enable no-console */
        }

        this.restore().then(data => {
          this._lastData = data;
          resolve();
        }).catch(reject);
      });
    }
    /**
      Restores all data currently saved in the `localStorage` identified by the
      `keyPrefix` as one plain object.
      @method restore
      @return {Object} All data currently persisted in the `localStorage`
    */


    restore() {
      return Ember.RSVP.resolve().then(() => {
        let data = localStorage.getItem(this.key);
        return JSON.parse(data) || {};
      });
    }
    /**
      Clears the store by deleting all `localStorage` keys prefixed with the
      `keyPrefix`.
      @method clear
    */


    clear() {
      localStorage.removeItem(this.key);
      this._lastData = {};
    }
    /**
      @method bindToStorageEvents
      @private
    */


    bindToStorageEvents() {
      (0, _jquery.default)(window).bind('storage', e => {
        if (e.originalEvent.key === this.key) {
          this.restore().then(data => {
            if (!(0, _objectsAreEqual.default)(data, this._lastData)) {
              this._lastData = data;
              this.trigger('sessionDataUpdated', data);
            }
          });
        }
      });
    }

  }, _temp)) || _class;

  _exports.default = Application;
});