define("sccog/utils/search-form-object", ["exports", "@ember-decorators/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SearchFormModel = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = (0, _object.observes)('startDateObject'), _dec4 = (0, _object.observes)('endDateObject'), classic(_class = (_class2 = (_temp = class SearchFormModel extends Ember.Object {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "q", null);

      _defineProperty(this, "title", null);

      _defineProperty(this, "referenceId", null);

      _defineProperty(this, "type", null);

      _defineProperty(this, "municipality", null);

      _defineProperty(this, "description", null);

      _defineProperty(this, "keywords", null);

      _initializerDefineProperty(this, "startDate", _descriptor, this);

      _initializerDefineProperty(this, "endDate", _descriptor2, this);

      _defineProperty(this, "fileName", null);

      _defineProperty(this, "propertyId", null);

      _defineProperty(this, "address", null);

      _defineProperty(this, "owner", null);
    }

    get startDateObject() {
      var date = this.startDate;
      return date ? new Date(date) : null;
    }

    set startDateObject(value) {
      this.startDate = value && value.toDateString();
    }

    onStartDateUpdated() {
      var dateObject = this.startDateObject || '';
      this.set('startDate', formatDate(dateObject) || null);
    }

    get endDateObject() {
      var date = this.endDate;
      return date ? new Date(date) : null;
    }

    set endDateObject(value) {
      this.endDate = value && value.toDateString();
    }

    onEndDateUpdated() {
      var dateObject = this.endDateObject || '';
      this.set('endDate', formatDate(dateObject) || null);
    }
    /**
     * Convert this object to JSON, excluding null or undefined values.
     */


    toSparseJSON() {
      var propertyNames = Object.keys(this);
      var sparseObject = propertyNames.reduce(function (result, prop) {
        var value = this.get(prop);

        if (value && prop !== 'startDateObject' && prop !== 'endDateObject') {
          result[prop] = value;
        }

        return result;
      }.bind(this), {});
      return sparseObject;
    }
    /**
     * Set the objects properties to the given values, or null if not provided.
     */


    reset(props) {
      var propertyNames = ['q', 'title', 'referenceId', 'type', 'municipality', 'description', 'keywords', 'startDate', 'endDate', 'fileName', 'propertyId', 'address', 'owner'];
      props = props || {};
      var newProperties = propertyNames.reduce(function (result, propertyName) {
        result[propertyName] = props[propertyName] || null;
        return result;
      }, {
        startDateObject: props.startDate ? new Date(props.startDate) : null,
        endDateObject: props.endDate ? new Date(props.endDate) : null
      });
      this.setProperties(newProperties);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "startDate", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "endDate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "onStartDateUpdated", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "onStartDateUpdated"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onEndDateUpdated", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "onEndDateUpdated"), _class2.prototype)), _class2)) || _class);

  window.__CLASSIC_OWN_CLASSES__.set(SearchFormModel, true);

  function formatDate(date) {
    if (!date || !date.getYear) {
      return null;
    }

    return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  }

  var _default = SearchFormModel;
  _exports.default = _default;
});