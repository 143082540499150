define("sccog/mixins/authenticated-route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var MyAuthenticatedRouteMixin = _authenticatedRouteMixin.default.reopen({
    roles: null,

    beforeModel(transition) {
      // Check first for authentication (see AuthenticatedRouteMixin).
      this._super(transition); // Check if the current user has one of the roles specified by the route.


      var session = this.session;
      var isAuthenticted = session.get('isAuthenticated');
      var hasNecessaryRole = this.roles ? this.roles.includes(session.get('role')) : true;

      if (isAuthenticted && !hasNecessaryRole) {
        transition.abort();
        this.session.set('attemptedTransition', transition); // Send an action for the application route to handle (similar to "sessionRequiresAuthentication" action).

        transition.send('sessionUnauthorized');
      }
    }

  });

  var _default = MyAuthenticatedRouteMixin;
  _exports.default = _default;
});