define("sccog/pods/document/index/route", ["exports", "sccog/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let IndexRoute = classic(_class = class IndexRoute extends Ember.Route.extend(_resetScroll.default) {}) || _class;

  _exports.default = IndexRoute;
});