define("sccog/pods/document/download/route", ["exports", "sccog/mixins/reset-scroll", "sccog/utils/get-qp", "sccog/utils/get-cookies", "sccog/utils/app-config"], function (_exports, _resetScroll, _getQp, _getCookies, _appConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let DownloadRoute = classic(_class = class DownloadRoute extends Ember.Route.extend(_resetScroll.default) {
    afterModel(document, transition) {
      let cookies = (0, _getCookies.default)();
      let token = this.get('session.token');
      let accessToken = cookies.accessToken || (0, _getQp.default)('accessToken') || this.get('session.data.authenticated.accessToken'); // Unavailable if no tokens or not open download

      if (!token && !accessToken && (!_appConfig.default.enableAnonymousAccess || _appConfig.default.enableAnonymousAccess && !_appConfig.default.enableAnonymousDownload)) {
        this.session.set('attemptedTransition', transition);
        this.transitionTo('document.download-unavailable');
        return;
      }

      let id = document.get('id');
      let url = '/api/documents/' + id + '/downloadfile';

      if (accessToken) {
        // TODO: test if we need this since it's in a cookie
        url += '?accessToken=' + accessToken;
      } else if (token) {
        let urlEncodedToken = encodeURIComponent(token);
        url += '?jwt=' + urlEncodedToken;
      }

      window.location.replace(url);
    }

  }) || _class;

  _exports.default = DownloadRoute;
});