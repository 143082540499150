define("sccog/utils/without", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * Similar to _.without. Removes items from an array, returning a new array.
   */
  function _default(array) {
    var removeValues = Array.prototype.slice.call(arguments, 1);
    var newArray = [];
    array.forEach(function (arrayValue) {
      // Only add `arrayValue` to `newArray` if it's not in our list of `removeValues`.
      if (removeValues.indexOf(arrayValue) === -1) {
        newArray.push(arrayValue);
      }
    });
    return newArray;
  }
});