define("sccog/components/app-version", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    <span title={{this.buildDate}}>
      Version {{this.version}}
    </span>
  </div>
  */
  {"id":"0n2EXsuF","block":"{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[10,\"span\"],[15,\"title\",[32,0,[\"buildDate\"]]],[12],[2,\"\\n    Version \"],[1,[32,0,[\"version\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sccog/components/app-version.hbs"}});

  const classic = __EMBER_CLASSIC_DECORATOR;
  let AppVersion = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('appConfig.config.version'), _dec4 = Ember.computed('appConfig.config.buildDate'), _dec(_class = classic(_class = (_class2 = (_temp = class AppVersion extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "appConfig", _descriptor, this);

      _initializerDefineProperty(this, "version", _descriptor2, this);
    }

    get buildDate() {
      var sDate = this.get('appConfig.config.buildDate');
      var result = sDate ? new Date(sDate).toLocaleString() : '';
      return result;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "appConfig", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "version", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "buildDate", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "buildDate"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = AppVersion;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AppVersion);
});