define("sccog/pods/components/date-picker/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const classic = __EMBER_CLASSIC_DECORATOR;
  let DatePicker = (_dec = (0, _component.tagName)(''), _dec(_class = classic(_class = class DatePicker extends Ember.Component {}) || _class) || _class);
  _exports.default = DatePicker;
});