define("sccog/initializers/leaflet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'leaflet',
    initialize: function () {
      L.Icon.Default.imagePath = '/assets/images';
    }
  };
  _exports.default = _default;
});