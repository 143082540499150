define("sccog/pods/documents/new/route", ["exports", "sccog/utils/ajax", "sccog/mixins/reset-scroll", "sccog/mixins/authenticated-route", "sccog/utils/dropdown-data"], function (_exports, _ajax, _resetScroll, _authenticatedRoute, _dropdownData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let NewRoute = (_dec = Ember._action, classic(_class = (_class2 = (_temp = class NewRoute extends Ember.Route.extend(_authenticatedRoute.default, _resetScroll.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "roles", ['editor', 'admin']);
    }

    model({
      properties
    }) {
      var document = this.store.createRecord('document', {}); // Get types and municipalities asynchronously.

      var documentTypes = (0, _dropdownData.getTypes)();
      var municipalities = (0, _dropdownData.getMunicipalities)(); // Return a hash of promises. Handle the hash of resolved stuff in `setupController`.
      // We have to do the lookups here instead of in `setupController` because this function
      // is meant to return a promise, and `setupController` is not.

      let hash = {
        model: document,
        documentTypes: documentTypes,
        municipalities: municipalities
      };

      if (properties) {
        let options = {
          data: {
            ids: properties
          }
        }; // Don't error everything if nothing returned

        hash.propertiesData = (0, _ajax.default)('/api/mapgeo/properties', options).catch(() => []);
      }

      return Ember.RSVP.hash(hash);
    } // Reset the query params when the model changes, or the route exits.


    resetController(controller) {
      controller.set('x', null);
      controller.set('y', null);
    }

    setupController(controller, resolved) {
      let propertiesData = resolved.propertiesData;
      delete resolved.propertiesData;
      resolved.thumbnailLimitHit = false;
      controller.setProperties(resolved); // Add properties to the properties list and the document

      if (propertiesData && propertiesData.features) {
        propertiesData.features.forEach(feature => {
          controller.send('addProperty', feature.properties);
        }); // Send to controller so that the map can add the parcels, see document-map component

        controller.set('propertiesData', propertiesData);
      }
    }

    deactivate() {
      var resolved = this.modelFor('docments.new');

      if (resolved && resolved.model && resolved.model.get('isNew')) {
        resolved.model.destroyRecord();
      }
    }

    refreshRoute() {
      this.refresh();
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "refreshRoute", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "refreshRoute"), _class2.prototype)), _class2)) || _class);
  _exports.default = NewRoute;
});