define("sccog/utils/get-cookies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getCookies;

  function getCookies() {
    let pairs = document.cookie.split(';');
    let cookies = {};
    pairs.forEach(raw => {
      let [key, value] = raw.split('=');
      key = String(key).trim();
      cookies[key] = window.unescape(value);
    });
    return cookies;
  }
});