define("sccog/pods/components/date-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pstOJb/p",
    "block": "{\"symbols\":[\"dp\",\"&attrs\",\"@selected\",\"@onChange\",\"@inputId\",\"@inputClass\"],\"statements\":[[11,\"div\"],[17,2],[12],[2,\"\\n  \"],[8,\"power-datepicker\",[],[[\"@selected\",\"@onSelect\"],[[32,3],[30,[36,0],[[32,0],[32,4]],[[\"value\"],[\"date\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"trigger\"]],[[24,\"tabindex\",\"-1\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"input\"],[15,1,[32,5]],[15,0,[31,[\"form-control \",[32,6]]]],[14,\"readonly\",\"\"],[15,2,[30,[36,1],[[32,3]],null]],[14,4,\"text\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[\"@class\"],[\"cal-dropdown-menu datepicker-small bootstrap-datepicker-theme\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"nav\"]],[],[[],[]],null],[2,\"\\n      \"],[8,[32,1,[\"days\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"format-date\"]}",
    "meta": {
      "moduleName": "sccog/pods/components/date-picker/template.hbs"
    }
  });

  _exports.default = _default;
});