define("sccog/utils/dropdown-data", ["exports", "sccog/utils/app-config", "sccog/utils/ajax"], function (_exports, _appConfig, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getTypes = getTypes;
  _exports.getMunicipalities = getMunicipalities;
  _exports.getUsedMunicipalities = getUsedMunicipalities;
  _exports.getRoles = getRoles;

  function getTypes() {
    return (0, _ajax.default)('/api/documents/types').then(function (result) {
      return result.types;
    });
  }

  function getMunicipalities() {
    return Ember.RSVP.resolve(_appConfig.default.municipalities);
  }

  function getUsedMunicipalities() {
    return (0, _ajax.default)('/api/documents/municipalities').then(function (result) {
      return result.municipalities;
    });
  }

  function getRoles() {
    return Ember.RSVP.resolve([{
      value: 'viewer',
      label: 'Viewer'
    }, {
      value: 'editor',
      label: 'Editor'
    }, {
      value: 'admin',
      label: 'Administrator'
    }]);
  }
});