define("sccog/pods/document/edit/route", ["exports", "sccog/mixins/authenticated-route", "sccog/mixins/reset-scroll", "sccog/utils/dropdown-data"], function (_exports, _authenticatedRoute, _resetScroll, _dropdownData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let EditRoute = classic(_class = (_temp = class EditRoute extends Ember.Route.extend(_authenticatedRoute.default, _resetScroll.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "roles", ['editor', 'admin']);
    }

    model() {
      var document = this.modelFor('document'); // Get types and municipalities asynchronously.

      var documentTypes = (0, _dropdownData.getTypes)();
      var municipalities = (0, _dropdownData.getMunicipalities)(); // Return a hash of promises. Handle the hash of resolved stuff in `setupController`.
      // We have to do the lookups here instead of in `setupController` because this function
      // is meant to return a promise, and `setupController` is not.

      return Ember.RSVP.hash({
        model: document,
        documentTypes: documentTypes,
        municipalities: municipalities
      });
    }

    setupController(controller, resolved) {
      resolved.thumbnailLimitHit = false;
      controller.setProperties(resolved);
    }

    deactivate() {
      var resolved = this.modelFor('document.edit');
      resolved.model.get('properties').forEach(function (p) {
        if (p.rollbackAttributes) {
          p.rollbackAttributes();
        }
      });
      resolved.model.rollbackAttributes();
    }

  }, _temp)) || _class;

  _exports.default = EditRoute;
});