define("sccog/pods/users/new/route", ["exports", "sccog/utils/dropdown-data", "sccog/mixins/authenticated-route", "sccog/mixins/reset-scroll"], function (_exports, _dropdownData, _authenticatedRoute, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let NewRoute = classic(_class = (_temp = class NewRoute extends Ember.Route.extend(_authenticatedRoute.default, _resetScroll.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "roles", ['admin']);
    }

    model() {
      var user = this.store.createRecord('user', {
        role: 'viewer'
      }); // Get roles and municipalities asynchronously.

      var roles = (0, _dropdownData.getRoles)();
      var municipalities = (0, _dropdownData.getMunicipalities)(); // Return a hash of promises. Handle the hash of resolved stuff in `setupController`.
      // We have to do the lookups here instead of in `setupController` because this function
      // is meant to return a promise, and `setupController` is not.

      return Ember.RSVP.hash({
        model: user,
        roles: roles,
        municipalities: municipalities
      });
    }

    setupController(controller, resolved) {
      controller.setProperties(resolved);
    }

    deactivate() {
      var model = this.modelFor('users.new');

      if (model && model.user && model.user.get('isNew')) {
        model.user.destroyRecord();
      }
    }

  }, _temp)) || _class;

  _exports.default = NewRoute;
});