define("sccog/services/app-config", ["exports", "sccog/utils/app-config"], function (_exports, _appConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AppConfigService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "config", _appConfig.default);
    }

  }

  _exports.default = AppConfigService;

  window.__CLASSIC_OWN_CLASSES__.set(AppConfigService, true);
});