define("sccog/utils/ajax", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = ajax;

  function ajax(url, options) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      options = options || {};
      options.url = url;
      options.contentType = 'application/json';
      let method = options.type || options.method;

      if (method && method.toLowerCase() !== 'get') {
        options.data = JSON.stringify(options.data);
      }

      options.success = function (data) {
        Ember.run(null, resolve, data);
      };

      options.error = function () {
        Ember.run(null, reject, arguments);
      };

      _jquery.default.ajax(options);
    });
  }
});