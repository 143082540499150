define("sccog/mixins/reset-scroll", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable ember/no-new-mixins
  var _default = Ember.Mixin.create({
    activate: function () {
      this._super();

      (0, _jquery.default)('.main').scrollTop(0);
    }
  });

  _exports.default = _default;
});