define("sccog/components/on-render", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    {{yield}}
  </div>
  */
  {"id":"gcdxJa2h","block":"{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sccog/components/on-render.hbs"}});

  const classic = __EMBER_CLASSIC_DECORATOR;
  let OnRender = (_dec = (0, _component.tagName)(''), _dec(_class = classic(_class = class OnRender extends Ember.Component {
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.action();
    }

  }) || _class) || _class);
  _exports.default = OnRender;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OnRender);
});