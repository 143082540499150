define("sccog/pods/loading/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let LoadingRoute = classic(_class = class LoadingRoute extends Ember.Route {
    activate() {
      super.activate();
      return Pace.restart();
    }

    deactivate() {
      super.deactivate();
      return Pace.stop();
    }

  }) || _class;

  _exports.default = LoadingRoute;
});