define("sccog/components/document-map", ["exports", "@ember-decorators/component", "@ember-decorators/object", "sccog/utils/app-config", "sccog/utils/map-tip"], function (_exports, _component, _object, _appConfig, _mapTip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='document-map' ...attributes>
    <div class='map' {{did-insert this.initMap}}></div>
  </div>
  */
  {"id":"j9ldUNV1","block":"{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"document-map\"],[17,1],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"map\"],[4,[38,0],[[32,0,[\"initMap\"]]],null],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}","meta":{"moduleName":"sccog/components/document-map.hbs"}});

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CDB_LAYER_SETTINGS = {
    cartodb_logo: false,
    https: true,
    infowindow: false,
    tooltip: false,
    legends: false,
    time_slider: false
  };
  let DocumentMap = (_dec = (0, _component.tagName)(''), _dec2 = Ember._action, _dec3 = (0, _object.observes)('document.geometries'), _dec(_class = classic(_class = (_class2 = (_temp = class DocumentMap extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "center", null);

      _defineProperty(this, "document", null);

      _defineProperty(this, "editable", null);

      _defineProperty(this, "drawnItems", null);
    }

    initMap(el) {
      let properties = this.properties;
      let geometries = this.get('document.geometries');
      let editable = this.editable;
      let basemapConfig = _appConfig.default.basemap; // Pick a default map center and zoom based on options or config.
      // If a center is provided, default to a low zoom level.

      let center = this.center || _appConfig.default.map && _appConfig.default.map.center;
      let zoom = this.center ? 18 : _appConfig.default.map && _appConfig.default.map.zoom; // Initialize map.

      let map = this.map = L.map(el, {
        fullscreenControl: true,
        scrollWheelZoom: false,
        zoom,
        center
      }); // Enable scroll wheel zoom when in fullscreen mode.

      map.on('fullscreenchange', function () {
        if (map.isFullscreen()) {
          map.scrollWheelZoom.enable();
        } else {
          map.scrollWheelZoom.disable();
        }
      }); // Remove attribution.

      map.attributionControl.setPrefix(''); // Add basemap.

      addBasemapLayers(map, basemapConfig); // Add drawing layer.

      let drawnItems = this.drawnItems = L.geoJson({
        type: 'FeatureCollection',
        features: []
      }, {
        style: constant({
          color: '#0168C1'
        })
      });
      map.addLayer(drawnItems); // properties loaded via query-params

      if (properties) {
        drawnItems.addData(properties);
        this.updateGeometries();
        map.fitBounds(drawnItems.getBounds());
      } // Add features to drawnItems layer.


      if (geometries) {
        // Convert multi to polygons, pass everything else through
        let polygons = geometries.geometries.reduce((all, item) => {
          if (item.type === 'MultiPolygon') {
            let polygonCollection = multiPolygonToPolygons(item);
            all.geometries = all.geometries.concat(polygonCollection.geometries);
          } else {
            all.geometries.push(item);
          }

          return all;
        }, {
          geometries: []
        });
        drawnItems.addData(toFeatureCollection(polygons));
        map.fitBounds(drawnItems.getBounds());
      } else if (center && zoom && !properties) {
        map.setView(center, zoom);
      }

      if (editable) {
        this.initDrawControl();
        this.initMapTip();
      }
    }

    initDrawControl() {
      let map = this.map;
      let drawnItems = this.drawnItems;
      let self = this; // Initialise the draw control and pass it the FeatureGroup of editable layers

      let drawControl = this.drawControl = new L.Control.Draw({
        position: 'topright',
        edit: {
          featureGroup: drawnItems
        },
        draw: {
          circle: false,
          rectangle: false,
          polyline: {
            metric: false
          }
        }
      });
      map.addControl(drawControl);
      map.on('draw:edited draw:deleted', function () {
        self.updateGeometries();
      });
      map.on('draw:created', function (e) {
        drawnItems.addLayer(e.layer);
        self.updateGeometries();
      });
    }

    initMapTip() {
      let self = this;
      let map = this.map; // Initialize map tip component thing.

      let mapTip = (0, _mapTip.default)({
        map: map
      }); // Setup event handlers.

      function toggleMapTip(shouldEnable) {
        return function () {
          if (shouldEnable) {
            mapTip.enable();
          } else {
            mapTip.disable();
          }
        };
      }

      function addGeometry(e) {
        let geojson = e.property; // Convert feature with MultiPolygon into a feature collection with Polygons.

        if (geojson.type === 'Feature' && geojson.geometry.type === 'MultiPolygon') {
          let polygonCollection = multiPolygonToPolygons(geojson.geometry);
          geojson = toFeatureCollection(polygonCollection);
        } // Add geojson to layer, and trigger an update.


        self.drawnItems.addData(geojson);
        self.updateGeometries();
      }

      function addProperty(e) {
        self.sendAction('addProperty', e.property.properties);
      }

      function addPropertyAndGeometry(e) {
        addProperty(e);
        addGeometry(e);
      } // Setup events.


      map.on('draw:drawstart draw:editstart draw:deletestart', toggleMapTip(false));
      map.on('draw:drawstop draw:editstop draw:deletestop', toggleMapTip(true));
      map.on('maptip:addproperty', addProperty);
      map.on('maptip:addgeometry', addGeometry);
      map.on('maptip:addboth', addPropertyAndGeometry);
    }

    getGeometryCollection() {
      return toGeometryCollection(this.drawnItems.toGeoJSON());
    }

    updateGeometries() {
      // Get the current geometry collection, or null if there isn't one (it was deleted).
      let geometries = this.getGeometryCollection() || null;
      this.set('document.geometries', geometries);
    }

    geometriesChanged() {
      if (!this.get('document.geometries')) {
        this.drawnItems.clearLayers();
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "initMap", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "initMap"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "geometriesChanged", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "geometriesChanged"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = DocumentMap;

  function toFeatureCollection(geometryCollection) {
    // Leaflet.draw does not support editing or deleting multipolygons
    let withoutMultiPolygons = geometryCollection.geometries.filter(geom => geom.type !== 'MultiPolygon');
    return {
      type: 'FeatureCollection',
      features: withoutMultiPolygons.map(function (geometry) {
        return {
          type: 'Feature',
          geometry: geometry,
          properties: {}
        };
      })
    };
  }

  function toGeometryCollection(featureCollection) {
    let out = {
      type: 'GeometryCollection',
      geometries: featureCollection.features.map(function (feature) {
        return feature.geometry;
      })
    };

    if (out.geometries.length > 0) {
      return out;
    }
  }

  function multiPolygonToPolygons(geometry) {
    return {
      geometries: geometry.coordinates.map(function (coords) {
        return {
          type: 'Polygon',
          coordinates: coords
        };
      })
    };
  }

  function constant(obj) {
    return obj;
  }

  function addBasemapLayers(map, basemapConfig) {
    if (!basemapConfig || !basemapConfig.length) {
      return;
    }

    basemapConfig.forEach(function (layer, i) {
      switch (layer.type) {
        case 'tile':
          {
            let options = Object.assign(layer.options || {}, {
              zIndex: i
            });
            L.tileLayer(layer.url, options).addTo(map);
            break;
          }

        case 'cartodb':
          cartodb.createLayer(map, layer.url, CDB_LAYER_SETTINGS).addTo(map, i);
          break;
      }
    });
  }

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DocumentMap);
});