define("sccog/pods/contact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gv+QQVYA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"page-header\"],[12],[2,\"\\n  Contact Information\\n\"],[13],[2,\"\\n\\n\"],[2,[32,0,[\"appConfig\",\"config\",\"contactHtml\"]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "sccog/pods/contact/template.hbs"
    }
  });

  _exports.default = _default;
});