define("sccog/utils/model-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterNew = filterNew;

  function filterNew(models) {
    return models.filter(function (model) {
      return !model.get('isNew');
    });
  }
});