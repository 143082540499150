define("sccog/services/session", ["exports", "jquery", "ember-simple-auth/services/session", "sccog/utils/ajax", "sccog/utils/get-cookies"], function (_exports, _jquery, _session, _ajax, _getCookies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _session.default.extend({
    appConfig: Ember.inject.service(),
    token: Ember.computed.alias('data.authenticated.token'),
    id: Ember.computed.alias('data.authenticated.id'),
    role: Ember.computed.alias('data.authenticated.role'),
    username: Ember.computed.alias('data.authenticated.username'),
    town: Ember.computed.alias('data.authenticated.town'),

    /**
     * Used on authenticate to populate session with MG Docs
     * user data. See usage in login-modal.
     */
    loadDocsData(authData) {
      let cookies = (0, _getCookies.default)();
      let source = authData || this.get('data.authenticated');
      let accessToken = cookies.accessToken || Ember.get(source, 'accessToken');
      let data = {
        accessToken
      }; // ignore oauth restore, since we don't have a token

      if (!accessToken) {
        return Ember.RSVP.resolve();
      }

      return (0, _ajax.default)('/oauth', {
        data,
        xhrFields: {
          withCredentials: true
        }
      }).then(user => {
        let authenticated = Ember.assign(source, user);
        let updated = {
          authenticated
        };
        this.setProperties('session', updated);
        return this.updateStore(updated);
      });
    },

    updateStore(data) {
      let authenticator = 'authenticator:jwt';
      data = data || this.data;
      Ember.set(data, 'authenticator', authenticator);
      Ember.set(data, 'authenticated.authenticator', authenticator);
      this.set('authenticator', authenticator);
      this.set('session.authenticator', authenticator);
      return this.store.persist(data).then(() => {
        return this.session.restore();
      });
    },

    setupSession: Ember.on('init', Ember.observer('isAuthenticated', function () {
      let isAuthenticated = this.isAuthenticated;
      let googleRestoreError = this.googleRestoreError;

      if (isAuthenticated && googleRestoreError) {
        return this.invalidate();
      }

      let token = this.token;
      let accessToken = this.get('data.authenticated.accessToken');
      let config = this.get('appConfig.config.mapgeoConfig');
      let domain = config ? `;domain=${config.communitySubdomain}.${config.base}` : '';
      let secure = config && config.protocol === 'https' ? ';secure' : '';

      if (token && !accessToken) {
        document.cookie = `jwttoken=${token}${domain}${secure}`;
      } else {
        // clear it
        document.cookie = `jwttoken=${domain}${secure}`;
      } // set token headers for all future ajax requests
      // ED headers setup in adapters/application


      _jquery.default.ajaxSetup({
        beforeSend: (xhr, settings) => {
          // Skip if not current domain
          if (settings.url.indexOf('http') !== -1 && settings.url.indexOf(location.hostname) === -1) {
            return;
          }

          let accessToken = this.get('data.authenticated.accessToken');
          xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
          xhr.setRequestHeader('Access-Token', accessToken);
        }
      });
    }))
  });

  _exports.default = _default;
});