define("sccog/adapters/application", ["exports", "@ember-data/adapter/rest", "ember-simple-auth/mixins/data-adapter-mixin", "ember-simple-auth-token/mixins/token-authorizer", "sccog/utils/get-cookies"], function (_exports, _rest, _dataAdapterMixin, _tokenAuthorizer, _getCookies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let Application = (_dec = Ember.computed('session.data.authenticated.token'), classic(_class = (_class2 = (_temp = class Application extends _rest.default.extend(_dataAdapterMixin.default, _tokenAuthorizer.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", 'api');
    }

    get headers() {
      let cookies = (0, _getCookies.default)();
      let token = this.get('session.data.authenticated.token');
      let accessToken = cookies.accessToken || this.get('session.data.authenticated.accessToken');
      return {
        Authorization: `Bearer ${token}`,
        'Access-Token': accessToken
      };
    } // TODO: remove when upgrading ESA to 1.2?
    // see https://github.com/simplabs/ember-simple-auth/pull/897#issuecomment-200325706

    /**
        This method is called for every response that the adapter receives from the
        API. If the response has a 401 status code it invalidates the session (see
        {{#crossLink "SessionService/invalidate:method"}}{{/crossLink}}).
        @method handleResponse
        @param {Number} status The response status as received from the API
        @protected
      */


    handleResponse(status) {
      if (status === 401 && this.get('session.isAuthenticated')) {
        this.session.invalidate();
      } else {
        return _rest.default.prototype.handleResponse.apply(this, arguments);
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "headers", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "headers"), _class2.prototype)), _class2)) || _class);
  _exports.default = Application;
});